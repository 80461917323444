import moment from 'moment-timezone';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { QuizSchedule } from 'src/api/schedule/types';

type Props = {
  quiz: QuizSchedule;
};

export const PageHelmet: FC<Props> = ({ quiz }) => {
  const { scheduledAt, title, imageUrl, description } = quiz;

  const dateStr = moment(scheduledAt)
    .tz('Europe/Moscow')
    .locale('ru')
    .format('D MMMM (dddd), в HH:mm');

  return (
    <Helmet htmlAttributes={{ translate: 'no' }}>
      <html lang="ru" prefix="og: http://ogp.me/ns#" />
      <meta property="og:type" content="website" />
      <title>{title}</title>
      <meta property="og:title" content={`${title} · ${dateStr}`} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {description && <meta property="description" content={description} />}
    </Helmet>
  );
};
