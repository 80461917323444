import { Card, Chip, Headline, Text, Title } from '@telegram-apps/telegram-ui';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';

import { BiSolidGame, BiSolidMap } from 'react-icons/bi';
import { QuizSchedule } from 'src/api/schedule/types';
import quiz_placeholder from 'src/assets/quiz-placeholder.jpg';
import { Space } from 'src/components/Space';
import { CoverImage } from './components/CoverImage';
import { Actions } from './components/Actions';

import styles from './index.module.css';

type ChipEntity = {
  label: string;
  backgroundColor: string;
  color: string;
  icon: React.ReactNode;
};

type Props = {
  item: QuizSchedule;
};

export const ScheduleItem: React.FC<Props> = ({ item }) => {
  const { title, description, scheduledAt, quizTitle, place, imageUrl } = item;

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const scheduledAtRender = useMemo(() => {
    const dateStr = moment(scheduledAt)
      .tz('Europe/Moscow')
      .locale('ru')
      .format('D MMMM (dddd), в HH:mm');
    if (moment().tz('Europe/Moscow').isSame(scheduledAt, 'day')) {
      return {
        date: `Сегодня, ${dateStr}`,
        className: styles.scheduledAtToday,
      };
    }
    if (moment().tz('Europe/Moscow').add(1, 'day').isSame(scheduledAt, 'day')) {
      return {
        date: `Завтра, ${dateStr}`,
        className: styles.scheduledAtTomorrow,
      };
    }
    return {
      date: dateStr,
      className: styles.scheduledAt,
    };
  }, [scheduledAt]);

  const chips = useMemo(() => {
    const backgroundColor = '#FF000090';
    const color = '#000';
    const r: ChipEntity[] = [];
    if (quizTitle) {
      r.push({
        label: quizTitle,
        backgroundColor,
        color,
        icon: <BiSolidGame size={20} />,
      });
    }
    if (place) {
      r.push({
        label: place,
        backgroundColor,
        color,
        icon: <BiSolidMap size={20} />,
      });
    }
    return r;
  }, [quizTitle, place]);

  return (
    <Card className={styles.card}>
      <div className={styles.imageAndTagsWrapper}>
        <CoverImage url={imageUrl ?? quiz_placeholder} height="30vh" />
      </div>
      <div className={styles.content}>
        <Space direction="vertical" size={8}>
          <Title level="2" weight="1" className={styles.title}>
            {title}
          </Title>
          <Headline className={scheduledAtRender.className}>
            {scheduledAtRender.date}
          </Headline>
          {chips.length > 0 && (
            <div className={styles.chips}>
              {chips.map(({ label, icon }, idx) => (
                <Chip
                  key={idx}
                  mode="elevated"
                  readOnly
                  className={styles.chip}
                >
                  {icon}
                  {label}
                </Chip>
              ))}
            </div>
          )}
          {description && <Text>{description}</Text>}
          <Actions item={item} />
        </Space>
      </div>
    </Card>
  );
};
