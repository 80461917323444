import { List } from '@telegram-apps/telegram-ui';
import { useMemo } from 'react';
import { PageBackground } from '../ScheduleGamePage/PageBackground';
import { ScheduleHeader } from '../SchedulePage/components/Schedule/components/ScheduleHeader';
import { DownloadTip } from './DownloadTip';

import styles from './index.module.css';

export const ScheduleGameCalendarDownloadPage = () => {
  const slug = useMemo(() => {
    const pathname = window?.location?.pathname;
    if (typeof pathname !== 'string' || !pathname) {
      return;
    }
    return pathname.split('/').filter(Boolean).slice(1).join('');
  }, []);

  return (
    <PageBackground>
      <List className={styles.centerPadding}>
        <ScheduleHeader city="" />
        {slug && <DownloadTip slug={slug} />}
      </List>
    </PageBackground>
  );
};
