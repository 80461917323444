import { Button, Text } from '@telegram-apps/telegram-ui';
import { useState } from 'react';

import { BiCalendarPlus, BiShare } from 'react-icons/bi';
import { QuizSchedule } from 'src/api/schedule/types';
import { Space } from 'src/components/Space';
import { ShareModal } from './components/ShareModal';

import { CalendarModal } from './components/CalendarModal';
import styles from './index.module.css';

type Props = {
  item: QuizSchedule;
};

export const Actions: React.FC<Props> = ({ item }) => {
  const { title, imageUrl, slug, cost, registerLink } = item;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  const shareUrl = `${window.location.origin}/game/${slug}/`;

  const handleSharePress = () => {
    setIsShareModalOpen(true);
  };

  const handleCalendarPress = () => {
    setIsCalendarModalOpen(true);
  };

  const handleShareModalOpenChange = (isOpen: boolean) => {
    setIsShareModalOpen(isOpen);
  };

  const handleCalendarModalOpenChange = (isOpen: boolean) => {
    setIsCalendarModalOpen(isOpen);
  };

  return (
    <>
      <div className={styles.shareBlock}>
        <Space className={styles.share}>
          {registerLink && (
            <Button
              mode="bezeled"
              size="s"
              onClick={() => window.open(registerLink)}
            >
              Регистрация
            </Button>
          )}
          <Button mode="outline" size="s" onClick={handleCalendarPress}>
            <BiCalendarPlus size={20} className={styles.icon} />
          </Button>
          <Button mode="outline" size="s" onClick={handleSharePress}>
            <BiShare size={20} className={styles.icon} />
          </Button>
        </Space>
        {cost && (
          <Text weight="3" className={styles.cost}>
            {cost} ₽
          </Text>
        )}
      </div>
      <ShareModal
        title={title}
        shareUrl={shareUrl}
        imageUrl={imageUrl}
        isOpen={isShareModalOpen}
        onOpenChange={handleShareModalOpenChange}
      />
      <CalendarModal
        item={item}
        isOpen={isCalendarModalOpen}
        onOpenChange={handleCalendarModalOpenChange}
      />
    </>
  );
};
