import { initHapticFeedback } from '@telegram-apps/sdk-react';
import { Button, Modal, Placeholder } from '@telegram-apps/telegram-ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import { useEffect, useState } from 'react';

import {
  BiCheck,
  BiLogoOkRu,
  BiLogoTelegram,
  BiLogoVk,
  BiLogoWhatsapp,
  BiSolidCopy,
} from 'react-icons/bi';
import { Space } from 'src/components/Space';

import styles from './index.module.css';

type Props = {
  title: string;
  shareUrl: string;
  imageUrl?: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const ShareModal: React.FC<Props> = ({
  title,
  shareUrl,
  imageUrl,
  isOpen,
  onOpenChange,
}) => {
  const copyToClipboardText = `${title}: ${shareUrl}`;

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }
    const timeout = setTimeout(() => {
      closeModal();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setIsCopied(false);
  }, [isOpen]);

  const closeModal = () => {
    onOpenChange(false);
  };

  const handleOnCopy = () => {
    const hapticFeedback = initHapticFeedback();
    hapticFeedback.impactOccurred('soft');
    setIsCopied(true);
  };

  return (
    <Modal open={isOpen} onOpenChange={onOpenChange} className={styles.modal}>
      <Placeholder description={title} header="Поделиться игрой" />
      <Space direction="vertical" className={styles.padding}>
        <CopyToClipboard text={copyToClipboardText} onCopy={handleOnCopy}>
          <Button
            mode="gray"
            size="l"
            className={styles.button}
            before={
              isCopied ? <BiCheck size={24} /> : <BiSolidCopy size={24} />
            }
          >
            {isCopied ? 'Скопировано' : 'Скопировать в буфер обмена'}
          </Button>
        </CopyToClipboard>
        <TelegramShareButton title={title} url={shareUrl}>
          <Button
            mode="gray"
            size="l"
            className={styles.button}
            before={<BiLogoTelegram size={24} />}
            onClick={closeModal}
          >
            Телеграм
          </Button>
        </TelegramShareButton>
        <VKShareButton title={title} url={shareUrl} image={imageUrl}>
          <Button
            mode="gray"
            size="l"
            className={styles.button}
            before={<BiLogoVk size={24} />}
            onClick={closeModal}
          >
            VK
          </Button>
        </VKShareButton>
        <WhatsappShareButton title={title} url={shareUrl}>
          <Button
            mode="gray"
            size="l"
            className={styles.button}
            before={<BiLogoWhatsapp size={24} />}
            onClick={closeModal}
          >
            WhatsApp
          </Button>
        </WhatsappShareButton>
        <OKShareButton title={title} url={shareUrl} image={imageUrl}>
          <Button
            mode="gray"
            size="l"
            className={styles.button}
            before={<BiLogoOkRu size={24} />}
            onClick={closeModal}
          >
            Одноклассники
          </Button>
        </OKShareButton>
      </Space>
    </Modal>
  );
};
